<template>
  <div>
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchUsers"
      ref="SSearch"
      v-if="SSearchShow"
    ></s-search>
    <div class="table-button table-button-flex">
      <div>
        <a-button
          v-if="
            isShowBtns.indexOf('ScheduleList-/api/constructionPlan/add') > -1
          "
          type="primary"
          @click="GoToBuildSchedule(record, 'add')"
          >新增</a-button
        >
      </div>
      <div>
        <a-button type="plain" icon="redo" @click="$refs.table.refresh(true)"
          >刷新</a-button
        >
        <columns-set
          tableName="ScheduleList"
          :columns="columns"
          @changeColumns="changeColumns"
        ></columns-set>
      </div>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="wsId"
      :columns="tableColumns"
      :data="loadData"
      :showPagination="true"
      :scroll="{ x: '1200px', y: 'calc(82vh - 150px )' }"
      tableLayout="fixed"
    >
      <!--  @change="changeTable" -->
      <span slot="wsBcId" slot-scope="text">
        <div>{{ CompaniesResult(text) }}</div>
      </span>

      <span slot="tbranchcompany" slot-scope="text">
        <div>{{ text.bcName }}</div>
      </span>
      <span slot="tpackageType" slot-scope="text">
        <div>{{ text ? text.ptName : "" }}</div>
      </span>
      <span slot="wsCreatetime" slot-scope="text">
        <div v-if="text == ''">{{ "-" }}</div>
        <div v-else>{{ $Format(text).slice(0, 10) }}</div>
      </span>

      <span slot="wsExpectTime" slot-scope="text">
        <div v-if="text == ''">{{ "-" }}</div>
        <div v-else>{{ $Format(text).slice(0, 10) }}</div>
      </span>
      <!-- 操作 -->
      <span slot="action" slot-scope="text, record" class="action">
        <a class="editBtn" @click="GoToBuildSchedule(record, 'edit')">详情</a>
        <a class="editBtn">发布预告</a>
        <a class="editBtn" @click="GoToSendOrder(record)">派单</a>
        <!-- <a
          class="editBtn"
          v-if="
            isShowBtns.indexOf('ScheduleList-/api/constructionPlan/update') > -1
          "
          @click="GoToBuildSchedule(record, 'edit')"
          >详情</a
        > -->
      </span>
    </s-table>
  </div>
</template>

<script>
import { STable, SSearch, ColumnsSet } from "@/components";
import { SelectOptions } from "@/config/SelectOptions.js";
import {
  getWorkSitelist,
  GetConstructionPlanList,
  DeletePackageTemplate,
} from "@/api/apiJF/schedule";
import { GetCompanies } from "@/api/device";

// 搜索条件
const queryItems = [
  {
    label: "设计师",
    value: "wsDesName",
    type: "input",
  },
  {
    label: "工地名称",
    value: "wsName",
    type: "input",
  },
  // {
  //   label: "客户姓名",
  //   value: "wsBcIds",
  //   type: "select",
  //   selectOption: "CompanyListUnAll",
  // },
  {
    label: "预计日期",
    value: "wsExpectTime",
    type: "dateRange",
    range: ["wsExpectStartTime", "wsExpectEndTime"],
    keyType: "WorkerAttend",
    defaultValue: [],
  },
  {
    label: "公司名称",
    value: "wsBcIds",
    type: "select",
    selectOption: "CompanyListUnAll",
  },
];
const columns = [
  {
    title: "工地",
    // width: "100px",
    scopedSlots: { customRender: "wsName" },
    dataIndex: "wsName",
    ellipsis: true,
    // align: "center",
  },
  {
    title: "客户姓名",
    width: "100px",
    dataIndex: "wsOName",
    align: "center",
  },
  {
    title: "所属公司",
    width: "200px",
    scopedSlots: { customRender: "wsBcId" },
    dataIndex: "wsBcId",
    align: "center",
  },
  {
    title: "施工平米",
    width: "100px",
    dataIndex: "wsQArea",
    align: "center",
  },
  {
    title: "设计师",
    width: "100px",
    dataIndex: "wsDesName",
    align: "center",
  },
  {
    title: "预计日期",
    width: "100px",
    scopedSlots: { customRender: "wsExpectTime" },
    dataIndex: "wsExpectTime",
    align: "center",
  },
  {
    title: "创建日期",
    width: "100px",
    scopedSlots: { customRender: "wsCreatetime" },
    dataIndex: "wsCreatetime",
    align: "center",
  },
  {
    title: "操作",
    width: "190px",
    scopedSlots: { customRender: "action" },
    fixed: "right",
    dataIndex: "action",
    align: "center",
  },
];
// const orderParam = ["ptBcIds"];

export default {
  name: "ScheduleList",
  components: {
    STable,
    SSearch,
    ColumnsSet,
  },
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      tableColumns: [],
      SSearchShow: true,
      // 查询参数
      queryParam: {
        wsDesName: "",
        wsName: "",
        // cpWpId: "1",
        // wsExpectTime: "",
        wsExpectStartTime: "",
        wsExpectEndTime: "",
        wsBcIds: "",
        pageNo: 1,
        pageSize: 10,
      },
      isShowBtns: [],
      record: {
        // wsBcIds: 0,
      },
      CompanyList: {},
      type: "",
      filters: {},
      CompanyListid: [],
      pageNo: 1,
      pageSize: 10,
    };
  },
  created() {
    this.GetCompaniesId();
    this.tableColumns = columns;
    this.changeColumns();
    this.filters = SelectOptions;
    // console.log(CompaniesResult(24));
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtnsJF").split(",");
  },
  methods: {
    // 获取分公司id
    GetCompaniesId() {
      GetCompanies()
        .then((res) => {
          // console.log(res);
          // this.filters.CompanyList.length = 0;

          res.data.forEach((el) => {
            this.CompanyListid.push(el.bc_id);
            this.CompanyList[el.bc_id] = el.bc_name;
          });
        })
        .then(() => {
          if (this.queryParam.wsBcIds == "") {
            this.queryParam.wsBcIds = this.CompanyListid.toString();
            // this.record.wsBcIds = this.CompanyListid.toString();
            this.$refs.table.refresh(true);
          }

          //当包含的分公司小于1个的时候，查询条件不显示分公司查询 表格不显示分公司名称
          if (this.CompanyListid.length < 1) {
            let data = [...this.queryItems];
            this.queryItems = data.filter((item) => item.label != "公司名称");
            if (this.queryItems.length == 0) {
              this.SSearchShow = false;
            }
            let data1 = [...this.columns];
            this.tableColumns = data1.filter(
              (item) => item.title != "公司名称"
            );
            this.columns = data1.filter((item) => item.title != "公司名称");
          }
        });
    },
    CompaniesResult(a) {
      return this.CompanyList[a];
    },
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(
        localStorage.getItem("ScheduleListColumnsSet")
      );
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = columns.filter((el) => value.includes(el.dataIndex));
      this.tableColumns = selectColumns.length ? selectColumns : columns;
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      if (parameter) {
        this.queryParam.pageNo = parameter.pageNo;
        this.queryParam.pageSize = parameter.pageSize;
      } else {
      }
      if (this.queryParam.wsBcIds != "") {
        let newObject = {};
        for (let el in this.queryParam) {
          if (this.queryParam[el] != "" && this.queryParam[el] != undefined) {
            newObject[el] = this.queryParam[el];
          }
        }
        let orderParam = Object.keys(newObject);
        const param = Object.assign({}, parameter, newObject);
        const requestParameters = this.$order(param, orderParam);
        const params = {
          ...requestParameters,
          verify: this.$verify(requestParameters, orderParam),
        };
        return getWorkSitelist(params).then((res) => {
          res.data.pageNo = res.data.page;
          res.data.totalCount = res.data.sum;
          // console.log("排期列表", res);
          return res.data;
        });
      }
    },

    fetchUsers(value) {
      // this.queryParam = value;
      for (let el in this.queryParam) {
        this.queryParam[el] = value[el];
      }
      // console.log("this.queryParam", this.queryParam, value);
      if (this.queryParam.wsBcIds == "") {
        this.queryParam.wsBcIds = this.CompanyListid.toString();
      }
      this.$refs.table.refresh(true);
    },

    // 跳往新建排期页面
    GoToBuildSchedule(record, type) {
      // console.log(record, type);
      let params = JSON.stringify(record);
      this.$router.push({
        path: "/BuildSchedule",
        // params: { params, type },
        query: { record: params, type: type },
      });
    },
    // 跳往派单页面
    GoToSendOrder(record) {
      console.log("派单", record);

      this.$router.push({
        name: "UndispatchedOrder",
        params: {
          cdBcIds: record.wsBcId,
          // cdWsId: record.wsId,
          cdWsName: record.wsName,
          // cdExpectEndTime: record.wsExpectTime,
          // cdExpectStartTime: record.wsExpectTime,
          // cdExpectTime: [record.wsExpectTime, record.wsExpectTime],
          // cdWsWIdJl: record.wsWIdJl,

          id: record.wsId,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.delName {
  color: red;
}
.editBtn {
  margin-right: 20px;
}
.table-button-flex {
  display: flex;
  justify-content: space-between;
}
</style>